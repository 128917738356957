
.dev-portal-custom-style {
  td > p {
    margin: 0 !important;
  }

  img {
    max-width: 100% !important;
    height: auto !important;
  }

  table {
    box-sizing: border-box !important;
  }

  tr, th, td {
    text-align: left;
    padding: 15px !important;
  }
}

.table-without-thead {
  .table-with-thead * {
    margin: 0 !important;
  }

  tr:nth-child(odd) {
    background-color: rgb(242, 240, 237) !important;
  }

  tr:first-child > td:first-child {
    border-left: 1px solid rgb(147, 140, 133) !important;
  }

  tr:first-child > td {
    border-top: 1px solid rgb(147, 140, 133) !important;
    border-bottom: 1px solid rgb(147, 140, 133) !important;
    border-right: 1px solid rgb(147, 140, 133) !important;
  }

  tr:not(:first-child) > td {
    border-bottom: 1px solid rgb(147, 140, 133) !important;
    border-right: 1px solid rgb(147, 140, 133) !important;
  }

  tr:not(:first-child) > td:first-child {
    border-left: 1px solid rgb(147, 140, 133) !important;
  }

  tr:first-child {
    background: rgb(247, 246, 244) !important;
    font-weight: bold !important;
  }

  tr:first-child > td:first-child {
    border-top-left-radius: 0.25rem !important;
  }

  tr:first-child > td:last-child {
    border-top-right-radius: 0.25rem !important;
  }

  tr:last-child > td:first-child {
    border-bottom-left-radius: 0.25rem !important;
  }

  tr:last-child > td:last-child {
    border-bottom-right-radius: 0.25rem !important;
  }
}

.table-with-thead {
  th {
    border-top: 1px solid rgb(147, 140, 133) !important;
    border-bottom: 1px solid rgb(147, 140, 133) !important;
    border-right: 1px solid rgb(147, 140, 133) !important;

    &:last-child {
      border-right: 1px solid rgb(147, 140, 133) !important;
      border-top-right-radius: 0.25rem !important;
    }

    &:first-child {
      border-left: 1px solid rgb(147, 140, 133) !important;
      border-top-left-radius: 0.25rem !important;
    }
  }

  td {
    border-bottom: 1px solid rgb(147, 140, 133) !important;
    border-right: 1px solid rgb(147, 140, 133) !important;
  }

  tr > td:first-child {
    border-left: 1px solid rgb(147, 140, 133) !important;
  }

  tr:last-child > td:first-child {
    border-bottom-left-radius: 0.25rem !important;
  }

  tr:last-child > td:last-child {
    border-bottom-right-radius: 0.25rem !important;
  }

  thead > tr:first-child {
		background: rgb(247, 246, 244) !important;
    font-weight: bold;
  }

  tr:nth-child(even) {
    background: rgb(242, 240, 237) !important;
  }
}


.table-of-contents {
    overflow: hidden auto;
    max-height: calc(100vh - 300px);
}

.h2-style {
	overflow-wrap: anywhere;
}

.content-wrap{
	overflow-wrap: break-word;
}